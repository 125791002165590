import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found - Rooam" />
    <div className="section full-screen">
      <div className="middle-content">
        <div className="container small text-center">
          <h2 className="text-white no-top">Page not found</h2>
          <p className="text-light-grey no-top-bottom">The page you are looking for doesn’t exist or has been moved.</p>
          <Link to="/" className="btn light no-bottom-margin">Back Home</Link>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
